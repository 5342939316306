@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham.eot');
  src: url('../assets/fonts/Gotham.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham.woff') format('woff'), url('../assets/fonts/Gotham.ttf') format('truetype'),
    url('../assets/fonts/Gotham.svg#Gotham') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('../assets/fonts/Gotham-Medium.eot');
  src: url('../assets/fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham-Medium.woff') format('woff'),
    url('../assets/fonts/Gotham-Medium.ttf') format('truetype'),
    url('../assets/fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Black';
  src: url('../assets/fonts/Gotham-Black.eot');
  src: url('../assets/fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham-Black.woff') format('woff'), url('../assets/fonts/Gotham-Black.ttf') format('truetype'),
    url('../assets/fonts/Gotham-Black.svg#Gotham-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('../assets/fonts/Gotham-Bold.eot');
  src: url('../assets/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham-Bold.woff') format('woff'), url('../assets/fonts/Gotham-Bold.ttf') format('truetype'),
    url('../assets/fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../assets/fonts/Gotham-Book.eot');
  src: url('../assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Gotham-Book.woff') format('woff'), url('../assets/fonts/Gotham-Book.ttf') format('truetype'),
    url('../assets/fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-gotham-bold: Arial, Helvetica, sans-serif;
$font-gotham-book: Arial, Helvetica, sans-serif;
$font-gotham-medium: Arial, Helvetica, sans-serif;
$font-poppins: Poppins, sans-serif;
$font-georgia: Georgia, sans-serif;

$colorPrimary: #083e59;
$colorSecondary: #1dacc2;
$colorOrange: #ed7d02;
$colorGreen: #95c13d;
$colorRed: #ff0000;
$colorYellow: #fed500;
$colorSaumon: #fffaf6;
$colorLightBleu: #e7f6f9;

$max-width-content: 1280px;
