@tailwind base;
@tailwind components;
@tailwind utilities;
@import './variables.scss';

body {
  margin: 0;
  font-family: $font-gotham-book, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-text {
    font-family: $font-gotham-bold;
    font-weight: bold;
    margin: 0;
    background: white;
    color: $colorOrange;
}

.leaflet-container {
    border-radius: 15px;
    width: 100%;
    height: 600px;
}

.leaflet-bottom.leaflet-right > .leaflet-control-attribution > a {
    display: none;
}

.leaflet-container .leaflet-marker-pane img {
    background: transparent;
    border: none;
}

.ant-card.card-marker{
    .ant-card-body{
        padding: 0;
    }
}

.ant-select-lg{
    min-width: 440px;
}

.logo-escda {
  max-height: 50px;
  width: auto;
}

.section-header {
  padding: 0.25rem;
}

.text-caution-app {
  font-weight: 500;
  font-size: 16px;
  margin: 0 22px;
  @media screen and (max-width: 800px) {
  font-size: 14px;
  }
}

.ant-select-item-option-content {
      white-space: inherit !important;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
}